<template>
  <b-modal
    :id="id"
    :title="title"
    :size="size"
    :no-close-on-backdrop="true"
    body-class="position-static"
    @show="handleShow"
    @hide="handleHide"
  >
    <div class="my-4">
      <b-form @submit.stop.prevent="submit" ref="form">
        <slot name="formfields"></slot>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cancelar
      </b-button>
      <b-button size="sm" variant="success" @click="submit">
        Guardar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import { formActions } from "@/mixins/actions.js";

export default {
  mixins: [formActions],
  props: {
    id: {
      type: String,
      default: "modal-form"
    },

    title: {
      type: String,
      default: ""
    },

    size: {
      type: String,
      default: "lg"
    }
  },

  data() {
    return {
      isLoading: false,
      bkFormFiles: {}
    };
  },

  methods: {
    handleShow() {
      this.$emit("show");
    },

    handleHide() {
      const me = this;
      const module = me.$parent;

      module.formFields = { ...me.bkFormFiles };
    }
  },

  mounted() {
    const me = this;
    me.bkFormFiles = { ...me.$parent.formFields };
  }
};
</script>

<style lang="scss" scoped></style>
