<template>
  <b-modal
    :id="modalId"
    title="Eliminar registro"
    body-class="position-static"
    :no-close-on-backdrop="true"
  >
    <div class="my-4">
      ¿Realmente desea eliminar el registro?
    </div>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="outline-inverse" @click="cancel()">
        Cancelar
      </b-button>
      <b-button size="sm" variant="danger" @click="deleteRow">
        Eliminar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: ["row", "isLoading", "id"],

  computed: {
    modalId() {
      return this.id ?? "modal-delete-record";
    }
  },

  methods: {
    deleteRow() {
      this.$emit("delete", this.row);
    }
  }
};
</script>

<style lang="scss" scoped></style>
