<template>
  <b-modal
    id="modal-documents-form"
    :title="title"
    size="lg"
    body-class="position-static"
    @show="handleShow"
  >
    <div class="my-4">
      <b-form @submit.stop.prevent="submit" ref="form">
        <input type="hidden" name="id" v-model="clientId" />
        <div class="flex space-x-2 items-center">
          <slot name="formfields"></slot>
          <b-button variant="primary" class="mt-2.5" @click="submit">
            Cargar
          </b-button>
        </div>
      </b-form>
      <v-client-table
        :data="records"
        :columns="columns"
        :options="options"
        ref="table"
      >
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button size="sm" variant="outline-primary" @click="showImage(row)">
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.modal-delete-document
          >
            <i class="glyphicon glyphicon-trash transform -translate-y-1"></i>
          </b-button>
        </div>
      </v-client-table>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cerrar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
    <CoolLightBox :items="images" :index="index" @close="index = null">
    </CoolLightBox>
    <delete-record-modal
      :row="selectedRow"
      id="modal-delete-document"
      @delete="deleteDoc"
    />
  </b-modal>
</template>

<script>
import { documentsActions } from "@/mixins/actions.js";
import DeleteRecordModal from "./DeleteRecordModal";
export default {
  props: ["title", "row"],
  mixins: [documentsActions],

  components: {
    DeleteRecordModal
  },

  data() {
    return {
      isLoading: false,
      records: [],
      columns: ["name", "actions"],
      clientId: null,
      options: {
        headings: {
          name: "Nombre",
          actions: ""
        },
        columnsClasses: {
          actions: "w-10"
        },
        sortable: [],
        filterable: false
      },
      images: [],
      index: null,
      selectedRow: null
    };
  },

  methods: {
    showImage(row) {
      const me = this;
      me.images = [row.path];
      me.index = 0;
    }
  }
};
</script>

<style lang="scss" scoped></style>
